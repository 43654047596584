/*global Daon */
/*eslint no-undef: "error"*/

import { Component } from "react";
import { connect } from "react-redux";
import { instanceOf } from "prop-types";

import {
  createFaceCaptureInstance,
  setIsFaceWasmLoaded,
} from "logic/actions/faceCapture";

import { go } from "logic/actions/navigation";
import { PAGE_SESSION_EXPIRED } from "logic/enums/pages";
import {
  CAPTURE_HEIGHT,
  CAPTURE_INTERVAL,
  CAPTURE_WIDTH,
} from "logic/enums/faceCapture";
import {
  createDocumentCaptureInstance,
  setIsDocumentWasmLoaded,
} from "logic/actions/documentCapture";

export class WasmDependenciesLoader extends Component {
  state = {
    isFaceCaptureInstanceCreated: false,
    numberOfDFQInvocations: 0,
    isDocumentCaptureInstanceCreated: false,
    numberOfIDCInvocations: 0,
  };

  static getDerivedStateFromProps(props, state) {
    let update = {};
    if (!!props.FaceCaptureInstance !== state.isFaceCaptureInstanceCreated) {
      update.isFaceCaptureInstanceCreated = true;
    }

    if (!!props.docCaptureInstance !== state.isDocumentCaptureInstanceCreated) {
      update.isDocumentCaptureInstanceCreated = true;
    }
    return update;
  }
  //#TODO  MOVE ceate faceCaptureInstance to FaceCaptureUI
  componentDidMount() {
    if (!this.state.isFaceCaptureInstanceCreated) {
      this.props.createFaceCaptureInstance({
        isFacePlusEnabled: this.props.isFacePlusEnabled,
        faceConfig: {
          width: CAPTURE_WIDTH,
          height: CAPTURE_HEIGHT,
          imageFormat: this.props.face?.options?.imageType ?? "image/png",
          compressionRate: this.props.face?.options?.compressionRate ?? 1,
          captureInterval: CAPTURE_INTERVAL,
          shouldCheckGyroscope: this.props.face?.options?.shouldCheckGyroscope
            ? this.props.face?.options?.shouldCheckGyroscope
            : "NONE",
        },
      });
    }

    /*
    if (!this.props.docCaptureInstance) {
      let resolution = {
        width: this.props.width ? parseInt(this.props.width) : 1920,
        height: this.props.height ? parseInt(this.props.height) : 1080
      }
      if (isIOS(navigator.userAgent)) {
        resolution = {
          width: 1280,
          height: 720,
          upscaledWidth: this.props.width ? parseInt(this.props.width) : 2816,
          upscaledHeight: this.props.height ? parseInt(this.props.height) : 1584,
        }
      }
      
      this.props.createDocumentCaptureInstance({
        width: resolution.width,
        height: resolution.height,
        upscaledWidth: resolution.upscaledWidth,
        upscaledHeight: resolution.upscaledHeight,
        captureType: "PORTRAIT",
        shouldSaveUnprocessed: this.props.fallbackToNative === "true" || this.props.fallbackToNative === true ? false : true,
      });
    }
    */
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.isFaceCaptureInstanceCreated !==
      this.state.isFaceCaptureInstanceCreated
    )
      this.loadFaceQualityModule();
    /*
  if (
    prevState.isDocumentCaptureInstanceCreated !==
    this.state.isDocumentCaptureInstanceCreated
  )
    this.loadDocumentCaptureModule();
*/
  }

  loadDocumentCaptureModule() {
    if (this.state.numberOfIDCInvocations < 20) {
      this.props.docCaptureInstance.loadWasmModules({
        urlIDDetectorWasm: window.location.origin + "/DaonIDCapture.wasm",
        onIDModuleInited: ({ isLoaded, error }) => {
          if (isLoaded) this.props.setIsDocumentWasmLoaded(true);
          if (error) {
            setTimeout(() => {
              this.loadDocumentCaptureModule();
            }, 5000);
            console.log(error);
          }
        },
        shouldSaveUnprocessed:
          this.props.fallbackToNative === "true" ||
          this.props.fallbackToNative === true
            ? false
            : true,
      });
    } else {
      this.props.go(PAGE_SESSION_EXPIRED);
    }

    this.setState({
      numberOfIDCInvocations: this.state.numberOfIDCInvocations++,
    });
  }

  loadFaceQualityModule() {
    if (this.state.numberOfDFQInvocations < 20) {
      this.props.FaceCaptureInstance.loadDFQModule({
        urlFaceDetectorWasm: window.location.origin + "/DaonFaceQuality.wasm",
        onFaceModuleLoaded: ({ isLoaded, error }) => {
          if (isLoaded) this.props.setIsFaceWasmLoaded(true);
          if (error) {
            setTimeout(() => {
              this.loadFaceQualityModule();
            }, 5000);
            console.log(error);
          }
        },
        onIdLiveCameraStopped: () => {
          this.props.FaceCaptureInstance.stopCamera();
        }
      });
    } else {
      this.props.go(PAGE_SESSION_EXPIRED);
    }

    this.setState({
      numberOfDFQInvocations: this.state.numberOfDFQInvocations++,
    });
  }

  render() {
    return null;
  }
}

export default connect(
  (state) => {
    return {
      ...state.configuration.extraConfig?.featureFlags,
      ...state.livenessTest,
      ...state.faceCapture,
      ...state.documentCapture,
      ...state.configuration.extraConfig.steps,
      ...state.configuration.extraConfig.steps?.face?.options,
      ...{
        fallbackToNative:
          state.configuration.extraConfig.steps?.document?.options
            ?.fallbackToNative,
      },
      ...{
        documentQualityHostname: state.configuration.documentQualityHostname,
      },
      width:
        state.configuration.extraConfig.steps?.document?.options?.width ||
        state.configuration.extraConfig?.featureFlags?.width,
      height:
        state.configuration.extraConfig.steps?.document?.options?.height ||
        state.configuration.extraConfig?.featureFlags?.height,
    };
  },
  {
    createFaceCaptureInstance,
    createDocumentCaptureInstance,
    go,
    setIsFaceWasmLoaded,
    setIsDocumentWasmLoaded,
  }
)(WasmDependenciesLoader);
