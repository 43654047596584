/*global Daon */
/*eslint no-undef: "error"*/

import React, { Component } from "react";
import { func, instanceOf } from "prop-types";
import { connect } from "react-redux";
import { isMobile, isIOS } from "logic/deviceType";
import { cameraResolution } from "logic/actions/documentCaptureConfiguration";
import ScrollingStopDetector from "../ScrollingStopDetector";
import { withTranslation } from "react-i18next";

export class DocumentCaptureUI extends Component {
  static propTypes = {
    onInitialized: func,
    onCameraStarted: func,
    documentCapture: instanceOf(Daon.DocumentCapture),
    setShowRemainingTime: func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isMobile: true,
      videoLoaded: false,
    };
    this.videoRef = new React.createRef();
    this.canvasOverlayRef = new React.createRef();
    if (typeof this.props.onInitialized === "function")
      this.props.onInitialized(this.props.docCaptureInstance);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.retryCameraInit !== this.props.retryCameraInit)
      !this.props.retryCameraInit && this.initializeCamera();
  }

  componentDidMount() {
    this.setState({
      isMobile: isMobile(navigator.userAgent),
    });
    this.setCanvasDimensions();
    if (isIOS(navigator.userAgent)) this.setState({ isIOS: true });
    if (isMobile(navigator.userAgent)) {
      this.setState({ isMobile: true });
      this.initializeCamera();
    } else {
      this.initializeCamera(this.props.cameraId);
    }

    this.setCanvasDimensions();
  }

  componentDidUpdate(prevProps, _) {
    if (prevProps.cameraId !== this.props.cameraId) {
      this.props.docCaptureInstance.stopCamera();
      this.initializeCamera(this.props.cameraId);
      this.setCanvasDimensions();
    }
  }

  setCanvasDimensions = () => {
    if (document.querySelector(".video-container")) {
      const { clientWidth, clientHeight } =
        document.querySelector(".video-container");

      if (
        this.videoRef.current.width != clientWidth ||
        this.videoRef.current.height != clientHeight
      ) {
        this.videoRef.current.width = clientWidth;
        this.videoRef.current.height = clientHeight;
        this.canvasOverlayRef.current.width = clientWidth;
        this.canvasOverlayRef.current.height = clientHeight;
      }
    }
  };

  initializeCamera(cameraId) {
    this.setState({ videoLoaded: false });
    if (this.videoRef && this.videoRef.current) {
      this.videoRef.current.srcObject = undefined;
    }
    // there are some bugs with camera selection on iOS, although ios devices have only 2 modes, front and back, we can use facingMode = env on init
    const initialCameraId = !this.state.isIOS ? cameraId : null;
    return this.props.docCaptureInstance
      .startCamera(
        {
          targetVideo: this.videoRef.current,
          overlayCanvas: this.canvasOverlayRef.current,
          aspectRatio: this.props.aspectRatio,
          overlayBackground:
            this.props.configuration.extraConfig?.steps?.document?.options
              ?.overlayColor ?? "rgba(0,0,0,1)",
        },
        initialCameraId
      )
      .then(({ videoEl }) => {
        const trackSettings =
          this.props.docCaptureInstance.camera.videoTracks[0].getSettings();
        this.setState({
          width: trackSettings.width,
          height: trackSettings.height,
        });
        this.props.cameraResolution({
          width: this.state.width,
          height: this.state.height,
        });
        if (typeof this.props.onCameraStarted === "function") {
          this.props.onCameraStarted();
          clearTimeout(this.timeout);

          this.showRemainingTimeTimeout = setTimeout(() => {
            this.props.setShowRemainingTime(true);

            this.hideRemainingTimeTimeout = setTimeout(() => {
              this.props.setShowRemainingTime(false);
            }, 4000);
          }, 30000);

          if (typeof this.props.onCaptureTimeout === "function") {
            this.timeout = setTimeout(() => {
              this.props.onCaptureTimeout();
            }, 60000);
          }
        }
      })
      .catch((err) => this.props.cameraPermissionDenied());
  }

  componentWillUnmount = () => {
    if (this.props.docCaptureInstance) {
      if (this.videoRef && this.videoRef.current) {
        this.videoRef.current.pause();
        this.videoRef.current.removeAttribute("src");
        this.videoRef.current.load();
        this.videoRef.current.remove();
        this.videoRef.current.srcObject = undefined;
      }
      this.props.docCaptureInstance.stopCamera();
    }

    clearTimeout(this.timeout);
    clearTimeout(this.showRemainingTimeTimeout);
    clearTimeout(this.hideRemainingTimeTimeout);
  };

  handleVideoLoaded = () => {
    this.setState({ videoLoaded: true });
  };

  render() {
    const isEdge = window.navigator.userAgent.indexOf("Edge") > -1;
    const shouldScaleEdge = isEdge ? "ui-canvas scale-edge" : "ui-canvas";
    let containerClassName;
    if (this.props.captureType === "LANDSCAPE") {
      containerClassName = this.state.isIOS
        ? "landscape-safari"
        : "others-landscape";
    } else if (isEdge) {
      containerClassName = "video-container hiddenEdge";
    } else {
      containerClassName = "video-container";
    }

    const shouldBeFlipped = !this.state.isMobile ? "flip-x" : "";
    const videoClassName = `calculating-canvas ${shouldScaleEdge} ${shouldBeFlipped}`;
    const overlayCanvasClassName = `overlay-canvas ${shouldScaleEdge}`;

    return (
      <div className={containerClassName}>
        <ScrollingStopDetector onStop={() => this.setCanvasDimensions()} />
        <video
          hidden={!this.state.videoLoaded}
          className={videoClassName}
          ref={this.videoRef}
          onLoadedMetadata={this.handleVideoLoaded}
          poster="noposter"
        ></video>
        <canvas
          className={overlayCanvasClassName}
          ref={this.canvasOverlayRef}
        />
      </div>
    );
  }
}

const componentWithTranslation = withTranslation()(DocumentCaptureUI);
export default connect(
  (state) => {
    return {
      ...state.documentCapture,
      ...state.camera,
      ...{
        configuration: state.configuration,
      },
    };
  },
  { cameraResolution }
)(componentWithTranslation);