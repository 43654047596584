
import styled from 'styled-components'



export const WrapperDiv = styled.div`
${props=>{
  return props.isInvisible ? "display:none;":"display:grid;"
  }}
  grid-column-gap: 2vw;
  column-gap: 2vw;
  grid-template-columns: 1fr 1fr;
  & .wrapper-span {
    border: 1px solid;
    border-radius: 5px;
    // align-items: center;
    border-color: rgba(220, 225, 227, 1);
    background-color: rgba(248, 249, 249, 1);
    text-decoration: none;
    margin-bottom: 15px;
    cursor: pointer;
  }
  & .center-div {
    width: fit-content;
    display: block;
    margin: auto;
    margin-top: 3%;
  }
`
export const Content = styled.div`
    margin-top:2vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
 
    position: relative;
    z-index: 8;

    @media only screen and (min-width:${props => props.theme.phoneBreakpoint + 'px'}) {
        justify-content: center;
    }
`;

export const Paragraph = styled.p`
    text-align: left;
    
    margin: ${({ margin }) => margin || "1em 0"};
    max-width: 800px;
`

export const ListItems = styled.div`
    color: ${props => props.theme.textPrimary};
    font-size: 0.9rem;
    li {
        margin: 5px auto
    }
`
export const VideoWrapper = styled.div`
      text-align: center; 

        @media(min-width: ${props => props.theme.phoneBreakpoint + 'px'}) {
            text-align: left;
            margin-bottom: 0;
          }
`
export const docDirection = styled.div`
          display: flex;
          flex-direction: row;
          justify-content: center;
          input {
            margin: 0;
            margin-right:0.5rem;
          }
          label {
            margin-right: 2rem;
            font-size: 16px;
            font-weight: 500;
          }
          @media(min-width: ${props => props.theme.phoneBreakpoint + 'px'}) {
            text-align: left;
            margin-bottom: 0;
            justify-content: flex-start;
          }
`

export const Para = styled.div`
    color: ${props => props.theme.textPrimary};
    font-size: 0.9rem;
    font-weight: 600;
    margin: 5px 0 0 5px;
`

export const H2 = styled.h2`
   margin-bottom: 0.5rem !important;
`
export const ShowHideVideo = styled.button`
    background: none; 
    border: none;
    padding: 0;
    text-align: right; 
    width: 100%; 
    cursor: pointer; 
    font: inherit;
    color: ${props => props.theme.mainColor};
`