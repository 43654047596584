import React, { Component } from "react";
import { connect } from "react-redux";
import { func, number, bool } from "prop-types";
import * as S from "./FaceCaptureUI.styles";
import { ReactComponent as CheckedIcon } from "assets/icons/check.svg";
import WasmDependenciesLoader from "Components/App/WasmDependenciesLoader";
import CountdownMessage from "../CountDownMessage";

class FaceCaptureUI extends Component {
  static propTypes = {
    width: number,
    height: number,
    onCameraStarted: func,
    sensorPermissionDenied: func,
    cameraCaptureStarted: bool,
  };

  state = {
    refocusedPage: false,
    videoLoaded: false,
    showCountdown: false,
  };

  constructor(props) {
    super(props);
    this.videoRef = new React.createRef();
  }

  componentDidMount() {
    this.initializeSensors();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.retrySensorInit !== this.props.retrySensorInit)
      !this.props.retrySensorInit && this.initializeSensors();
  }
  sendRef() {
    const videoRef = this.videoRef.current;
    const cameraId = this.props.cameraId;
    this.props.handleVideoRefChange(videoRef, cameraId);
  }
  componentDidUpdate(prevProps, _) {
    if (
      prevProps.cameraCaptureStarted !== this.props.cameraCaptureStarted &&
      this.props.cameraCaptureStarted
    ) {
      this.startCountdown();
      this.setState({ showCountdown: true });
    }

    if (prevProps.cameraId !== this.props.cameraId) {
      this.props.FaceCaptureInstance.stopCamera();
      this.props.FaceCaptureInstance.pause();
      this.initializeSensors(this.props.cameraId);
    }
  }

  componentWillUnmount() {
    if (this.props.FaceCaptureInstance) {
      if (this.videoRef && this.videoRef.current) {
        this.videoRef.current.pause();
        this.videoRef.current.removeAttribute("src");
        this.videoRef.current.load();
        this.videoRef.current.remove();
        this.videoRef.current.srcObject = undefined;
      }

      this.props.FaceCaptureInstance.stopCamera();
      this.props.FaceCaptureInstance.pause();
    }
  }

  initializeSensors = (cameraId) => {
    this.setState({ videoLoaded: false });
    // if flag for checking gyroscope is not set (default behavior) we should skip checking gyroscope activity and just check camera
    let gyroscopeCheckerPromise = Promise.resolve(true);
    if (this.props.shouldCheckGyroscope != "NONE") {
      gyroscopeCheckerPromise =
        this.props.FaceCaptureInstance.isGyroscopeActive();
    }
    gyroscopeCheckerPromise
      .then((isGyroscopeActive) => {
        this.props.updateGyroscope(isGyroscopeActive);
        this.sendRef();
        return this.props.FaceCaptureInstance.startCamera(
          this.videoRef.current,
          cameraId
        );
      })
      .then(() => {
        if (typeof this.props.onCameraStarted === "function")
          this.props.onCameraStarted(this.videoRef.current);
      })
      .catch((error) => {
        if (error.code === 2 || error.code === 3) {
          this.props.updateGyroscope(false);
        }
        this.props.sensorPermissionDenied(error);
      });
  };

  startCountdown = () => {
    setTimeout(() => {
      this.setState({ showCountdown: false });
    }, 6000);
  };

  handleLoadedMetadata = () => {
    this.setState({ videoLoaded: true });
  };

  render() {
    const isEdge = window.navigator.userAgent.indexOf("Edge") > -1;
    const videoClassName = isEdge ? "ui-canvas scale-edge" : "ui-canvas";
    const containerClassName = isEdge
      ? "video-container hiddenEdge"
      : "video-container";
    const showIndicator = this.props?.className?.includes("face-passed");

    return (
      <div className={containerClassName}>
        <WasmDependenciesLoader />
        {this.state.showCountdown && <CountdownMessage />}

        <S.Video
          hidden={!this.state.videoLoaded}
          className={videoClassName}
          hidded={!this.state.videoLoaded}
          autoPlay
          playsInline
          ref={this.videoRef}
          poster="noposter"
          onLoadedMetadata={this.handleLoadedMetadata}
          shouldRotate={this.props.isFacePlusEnabled}
        ></S.Video>
        {isEdge ? null : (
          <div className={this.props.className || ""}>
            {showIndicator && (
              <S.IndicatorWrapper>
                <S.CaptureIndicator background="#66cb9f" className="indicator">
                  <CheckedIcon />
                </S.CaptureIndicator>
              </S.IndicatorWrapper>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default connect((store) => {
  const isFacePlusEnabled =
    store.configuration.extraConfig?.steps?.face?.options?.isFacePlusEnabled;
  return {
    FaceCaptureInstance: store.faceCapture.FaceCaptureInstance,
    ...store.camera,
    isFacePlusEnabled,
  };
})(FaceCaptureUI);
