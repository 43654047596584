import { Component } from "react";
import { connect } from "react-redux";
import { string, instanceOf } from "prop-types";
import "./style.css";
import PageContent from "../../../PageContent";
import {
  ID_CARD,
  DRIVERS_LICENCE,
  OTHER_DOCUMENT,
  PASSPORT,
  RESIDENCE_CARD,
  ID_CARD_LAND_PORT,
  DOCUMENT_SIDE_BACK,
  DOCUMENT_SIDE_FRONT,
  DOCUMENT_INSTRUCTIONS,
} from "logic/enums/document";
import { go, nextStep } from "logic/actions/navigation";
import { isMobile } from "logic/deviceType";

import {
  retryProcessing,
  setCroppedFirstPage,
  retry,
  resetUserTimeout,
} from "logic/actions/documentCapture";
import { isSinglePaged } from "logic/actions/documentCaptureConfiguration";
import {
  DOCUMENT_CAPTURE_PORTRAIT,
  DOCUMENT_CAPTURE_INSTRUCTIONS,
} from "logic/enums/pages";
import LinkNativeCapture from "../../../LinkNativeCapture";
import {
  preview,
  selectDocumentType,
  setDocumentId,
} from "logic/actions/documentCapture";
import { blobToBase64 } from "logic/blobToBase64";
import "react-dropdown/style.css";
import { documentsSave } from "logic/actions/api/document";
import { withTranslation } from "react-i18next";
import * as S from "./PageDocumentCaptureInstructions.styles";
import idVideo from "assets/videos/document_capture_id.webp";
import passportVideo from "assets/videos/document_capture_passport.webp";
import InstructionVideo from "Components/common/InstuctionVideo";
import { logEvent, document_capture_native } from "logic/eventLogger";
import { getTimePassed, startEventTimer } from "logic/stepTimers";
import DaonButton from "Components/common/DaonButton";
import DaonErrorBox from "Components/common/DaonErrorBox";
import SubmitDocument from "Components/modules/common/SubmitDocument";
import { getTranslatedContent } from "logic/documentTranslation";

const START = 0;
export class PageDocumentCaptureInstructions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pending: false,
      message: "",
      formState: START,
      isMobile: isMobile(navigator.userAgent),
      calledCount: 0,
      displayVideo: true,
    };

    if (!getTimePassed("document_capture")) startEventTimer("document_capture");
  }

  static propTypes = {
    documentType: string,
    base64CroppedFirstPage: string,
    configuration: instanceOf(Object),
    documentCapture: instanceOf(Object),
  };

  UNSAFE_componentWillMount() {
    if (
      this.props.steps?.document?.options?.fallbackToNative === "true" ||
      this.props.steps?.document?.options?.fallbackToNative === true ||
      (!this.state.isMobile &&
        !(
          this.props.hideDocumentUpload === true ||
          this.props.hideDocumentUpload === "true"
        ))
    ) {
      const documentTypeForSdk =
        this.props.documentType === PASSPORT ? PASSPORT : ID_CARD_LAND_PORT;
      this.setState({
        docCaptureInstance: new Daon.DocumentCapture({
          url: this.props.documentQualityHostname,
          width: this.props.docCaptureWidth
            ? parseInt(this.props.docCaptureWidth)
            : 3840,
          height: this.props.docCaptureHeigth
            ? parseInt(this.props.docCaptureHeigth)
            : 2160,
          facingMode: "environment",
          shouldSaveUnprocessed: false,
          compressionRate: this.props.compressionRate,
          documentType: documentTypeForSdk,
        }),
      });
    }
  }

  componentDidMount() {
    document.title = `${this.props.t(
      "PageDocumentCapture.title"
    )} | Onboarding`;
    if (
      !this.props.base64CroppedFirstPage &&
      this.props.documentType !== PASSPORT
    ) {
      this.setState({ hintFacePresent: true }); //should we keep this ??
      this.documentPagesNum(this.props.documentType);
    }
    if (this.props.shouldSkipInstructionsPage) {
      this.props.go(DOCUMENT_CAPTURE_PORTRAIT);
    }
  }

  onUpload = () => {
    if (this.state.inputRef && this.state.inputRef.current) {
      const inputRef = { ...this.state.inputRef };
      inputRef.current.value = "";
      this.state.inputRef.current.click();
    }
  };

  saveNativeButtonRef = (inputRef) => {
    this.setState({ inputRef });
  };

  nativeCapture = (blob) => {
    logEvent(document_capture_native);

    if (isMobile(navigator.userAgent)) {
      this.setState({
        pending: true,
      });
      this.state.docCaptureInstance
        .assessQuality(blob, {
          hintFacePresent: this.state.hintFacePresent,
          maxCroppedDPI: this.props.steps?.document?.options?.nativeMaxDpi,
          croppingTolerance:
            this.props.steps?.document?.options?.croppingTolerance,
        })
        .then((response) => {
          if (response.result === "FAIL") {
            this.setState({ message: response.feedback });
          } else {
            blobToBase64(blob).then(() => {
              this.props.preview({
                base64:
                  "data:image/jpeg;base64, " + response.documentImage.image,
                isRejected: false,
                captureMethod: this.state.captureMethod,
              });
            });
          }
          this.setState({
            pending: false,
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            pending: false,
          });
          let errorMessage = this.props.t(
            "PageDocumentCaptureInstructions.error_document_quality"
          );
          if (error.response?.status === 413) {
            errorMessage = this.props.t(
              "PageDocumentCaptureInstructions.error_file_size"
            );
          }
          this.setState({ message: errorMessage });
        });
    } else {
      blobToBase64(blob)
        .then((base64) => {
          if (
            this.props.base64CroppedFirstPage ||
            this.props.documentType === PASSPORT ||
            this.props.documentCaptureConfiguration.singlePaged
          ) {
            this.setState({
              pending: true,
            });
            this.submit(base64);
          } else {
            this.props.setCroppedFirstPage(base64);
          }
        })
        .catch((error) => {
          this.setState({
            pending: false,
          });
          let errorMessage = error;
          if (error.response?.status === 413) {
            errorMessage = this.props.t(
              "PageDocumentCaptureInstructions.error_file_size"
            );
          }
          this.setState({ message: errorMessage });
        });
    }
  };

  submit = (base64) => {
    if (
      this.props.documentCaptureConfiguration.singlePaged ||
      this.props.documentType === PASSPORT
    ) {
      this.setState({
        firstPage: base64,
        calledCount: this.state.calledCount + 1,
      });
    } else {
      this.setState({
        secondPage: base64,
        firstPage: this.props.base64CroppedFirstPage,
        calledCount: this.state.calledCount + 1,
      });
    }
  };

  documentPagesNum = (type) => {
    if (type?.includes(ID_CARD)) {
      if (this.props.documentCaptureConfiguration.docCountryOptions.id === 1) {
        this.props.isSinglePaged(true);
      } else {
        this.props.isSinglePaged(false);
      }
    } else if (type?.includes(DRIVERS_LICENCE)) {
      if (this.props.documentCaptureConfiguration.docCountryOptions.dl === 1) {
        this.props.isSinglePaged(true);
      } else {
        this.props.isSinglePaged(false);
      }
    } else if (type?.includes(RESIDENCE_CARD)) {
      if (this.props.documentCaptureConfiguration.docCountryOptions.rc === 1) {
        this.props.isSinglePaged(true);
      } else {
        this.props.isSinglePaged(false);
      }
    } else {
      if (
        this.props.documentCaptureConfiguration.docCountryOptions[
          this.props.documentLabel
        ] === 1
      ) {
        this.props.isSinglePaged(true);
      } else {
        this.props.isSinglePaged(false);
      }
    }
  };

  onErrorHandler = ({ pending, error }) => {
    this.props.retry(true);
    this.setState({ pending, message: error });
  };
  handleNavigation = () => {
    if (this.props.userTimeout) {
      this.props.resetUserTimeout();
    }
    this.props.go(DOCUMENT_CAPTURE_PORTRAIT);
  };

  updateDisplayProperty = () => {this.setState({ displayVideo: !this.state.displayVideo })}
  
  componentWillUnmount() {
    if (this.state.docCaptureInstance) {
      this.setState({
        docCaptureInstance: new Daon.DocumentCapture({
          url: this.props.documentQualityHostname,
          width: this.props.docCaptureWidth
            ? parseInt(this.props.docCaptureWidth)
            : 3840,
          height: this.props.docCaptureHeigth
            ? parseInt(this.props.docCaptureHeigth)
            : 2160,
          facingMode: "environment",
          compressionRate: this.props.compressionRate,
          shouldSaveUnprocessed: false,
        }),
      });
    }
  }

  render() {
    const shouldShowNative =
      this.props.steps?.document?.options?.fallbackToNative === "true" ||
      this.props.steps?.document?.options?.fallbackToNative === true;

    let translatedContent;
    let translationPage;
    let title;
    let subtitle;

    const subtitlePrefix = this.props.t(
      "PageDocumentCaptureInstructions.instuctions_small"
    );
    const documentSide = this.props.base64CroppedFirstPage
      ? DOCUMENT_SIDE_BACK
      : DOCUMENT_SIDE_FRONT;

    document.title = `Document ${documentSide} | Onboarding`;

    const documentType = this.props.documentType || OTHER_DOCUMENT;

    if (
      this.props.documentLabel &&
      !this.props.documentLabel.includes("PageVerifyIdentity")
    ) {
      const documentSideTranslated = this.props.t(
        `PageDocumentCaptureInstructions.document_label_${documentSide}`
      );
      translationPage = DOCUMENT_INSTRUCTIONS;
      translatedContent = getTranslatedContent({
        documentSide,
        translate: this.props.t,
        translationPage,
        subtitlePrefix,
        documentLabel: this.props.documentLabel,
        documentSideTranslated,
      });
    } else {
      translationPage = DOCUMENT_CAPTURE_INSTRUCTIONS;
      translatedContent = getTranslatedContent({
        documentSide,
        translate: this.props.t,
        translationPage,
        subtitlePrefix,
        documentType,
      });
    }

    title = translatedContent?.title;

    subtitle = translatedContent?.subtitle;
    return (
      <PageContent
        toggleLoading={this.state.pending}
        title={this.props.t("PageDocumentCapture.title")}
        justifyContent="center"
        instructions={true}
        showBack={!this.props.skipDocumentSelection}
      >
        <S.Content>
          {this.props.userTimeout && (<p hidden>{this.props.t("PageDocumentCapture.timeout")}</p>)}
          <S.H2> {title}</S.H2>
          {subtitle.trim() && <S.Paragraph margin="0 0 10px 0">{subtitle}</S.Paragraph>}

          {isMobile(navigator.userAgent) && (
            <>
              {this.state.displayVideo && <InstructionVideo
              title={this.props.documentType === PASSPORT ? this.props.t("PageDocumentCaptureInstructions.video_title_passport") : this.props.t("PageDocumentCaptureInstructions.video_title_documents")}
              alt={this.props.documentType === PASSPORT ? this.props.t("PageDocumentCaptureInstructions.video_alt_passport") :this.props.t("PageDocumentCaptureInstructions.video_alt_documents")}
              video={
                this.props.documentType === PASSPORT ? passportVideo : idVideo
              }
            ></InstructionVideo>}
            <S.ShowHideVideo
              style={{ margin: "1rem 0" }}
              onClick={this.updateDisplayProperty}
              id="display_video"
            >
              {this.state.displayVideo ? this.props.t("Common.hide_video") : this.props.t("Common.display_video")}
            </S.ShowHideVideo>
            </>
            
          )}
          {this.props.t("PageDocumentCaptureInstructions.make_sure").trim() && <S.Para>{this.props.t("PageDocumentCaptureInstructions.make_sure")}</S.Para>}
          {this.props.shouldShowBulletPoints && <S.ListItems>
            <ul>
              {this.props.t("PageDocumentCaptureInstructions.bullet_point_one").trim() && <li>{this.props.t("PageDocumentCaptureInstructions.bullet_point_one")}</li>}
              {this.props.t("PageDocumentCaptureInstructions.bullet_point_two").trim() &&<li>{this.props.t("PageDocumentCaptureInstructions.bullet_point_two")}</li>}
              {this.props.t("PageDocumentCaptureInstructions.bullet_point_three").trim() &&<li>{this.props.t("PageDocumentCaptureInstructions.bullet_point_three")}</li>}
            </ul>
          </S.ListItems>}
          {!this.props.shouldShowBulletPoints && <S.Paragraph margin="10px 0">
            {this.props.t(
              "PageDocumentCaptureInstructions.document_example_caption"
            )}
          </S.Paragraph>}
        </S.Content>
        <div>
          {this.state.message && (
            <DaonErrorBox
              message={this.state.message}
              whiteBackground
            ></DaonErrorBox>
          )}
          {this.state.calledCount > 0 && (
            <SubmitDocument
              error={this.state.message}
              key={this.state.calledCount}
              errorHandler={this.onErrorHandler}
              documentsObject={{
                base64CroppedFirstPage: this.state.firstPage,
                base64CroppedSecondPage: this.state.secondPage,
              }}
              base64CroppedFirstPage={this.state.firstPage}
              base64CroppedSecondPage={this.state.secondPage}
              documentType={this.props.documentType}
              isNFCReady={this.props.isNFCReady}
              mobileAppPlatform={this.props.mobileAppPlatform}
            />
          )}
          {(shouldShowNative ||
            (!this.state.isMobile &&
              !(
                this.props.hideDocumentUpload === true ||
                this.props.hideDocumentUpload === "true"
              ))) && (
            <LinkNativeCapture
              class={"btn green"}
              onClick={this.onCaptureClick}
              onUploaded={this.nativeCapture}
              facingMode="environment"
              onButtonClicked={this.onUpload}
              onNativeButtonReady={this.saveNativeButtonRef}
            />
          )}
          {!shouldShowNative && (
            <DaonButton
              style={{ margin: "1rem 0" }}
              onClick={this.handleNavigation}
              id="take_photo"
            >
              {this.props.t("PageDocumentCaptureInstructions.btn_take_a_photo")}
            </DaonButton>
          )}
        </div>
      </PageContent>
    );
  }
}
const componentWithTranslation = withTranslation()(
  PageDocumentCaptureInstructions
);
export default connect(
  (state) => {
    const { featureFlags } = state.configuration.extraConfig;

    return {
      shouldShowBulletPoints: featureFlags.shouldShowBulletPoints || false,
      dialCountryNum: state.documentCaptureConfiguration.dialCountryNum,
      hideDocumentUpload:
        state.configuration.extraConfig.steps?.document?.options
          ?.hideDocumentUpload || featureFlags.hideDocumentUpload,
      documentCaptureConfiguration: state.documentCaptureConfiguration,
      processingRetriesCounter: state.documentCapture.processingRetriesCounter,
      userTimeout: state.documentCapture.userTimedOut,
      documentProcessorRetries:
        featureFlags?.documentProcessorRetries ||
        state.configuration.extraConfig.steps?.document?.options
          ?.documentProcessorRetries ||
        3,
      shouldSkipInstructionsPage:
        state.configuration.extraConfig.steps?.document?.options
          ?.shouldSkipInstructionsPage,
      compressionRate:
        state.configuration.extraConfig.steps?.document?.options
          ?.compressionRate,
      base64CroppedFirstPage: state.documentCapture.base64CroppedFirstPage,
      documentType: state.documentCapture.documentType,
      retries: state.documentCapture.retries,
      documentLabel: state.documentCapture.documentLabel,
      mobileAppPlatform: state.composite.mobileAppPlatform,
      isNFCReady: state.documentCapture.isNFCReady,
      steps: state.configuration.extraConfig.steps,
      skipDocumentSelection: state.documentCapture.skipDocumentSelection,
      documentQualityHostname: state.configuration.documentQualityHostname
        ? `${state.configuration.documentQualityHostname}/rest/v1/quality/assessments`
        : "https://emea-saas-staging-docquality.identityx-cloud.com/rest/v1/quality/assessments",
    };
  },
  {
    go,
    nextStep,
    preview,
    isSinglePaged,
    documentsSave,
    setCroppedFirstPage,
    selectDocumentType,
    retryProcessing,
    retry,
    setDocumentId,
    resetUserTimeout,
  }
)(componentWithTranslation);
