import styled from "styled-components";

export const MainWrapper = styled.div`
  & p {
    font-size: 14px;
    padding: 2px;
  }
`;

export const Button = styled.button`
  margin-top: 10%;
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: relative;
  z-index: 8;
  min-height: 78vh;

  @media only screen and (min-width: ${(props) =>
      props.theme.phoneBreakpoint + "px"}) {
    justify-content: center;
  }
`;

export const Paragraph = styled.p`
  text-align: left;

  margin: ${({ margin }) => margin || "1em 0"};
`;

export const ListItems = styled.div`
  color: ${(props) => props.theme.textPrimary};
  font-size: 0.9rem;
  @media (max-width: ${(props) => props.theme.phoneBreakpoint + "px"}) {
    ul {
      margin-bottom: 1rem;
    }
  }

  li {
    margin: 5px auto;
  }
`;
export const Para = styled.div`
  color: ${(props) => props.theme.textPrimary};
  font-size: 0.9rem;
  font-weight: 600;
  margin: 5px 0 0 5px;
`;

export const H2 = styled.h2`
  margin-bottom: 0.5rem !important;
`;
export const ShowHideVideo = styled.button`
  background: none;
  border: none;
  padding: 0;
  text-align: right;
  width: 100%;
  cursor: pointer;
  font: inherit;
  color: ${(props) => props.theme.mainColor};
`;
export const StyledList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const StyledListItem = styled.li`
  position: relative;
  padding-left: 1.5em;
  list-style-type: none;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 0.5em;
    height: 0.5em;
    background-color: #000; /* Adjust bullet color */
    border-radius: 50%; /* Circular bullet */
  }
`;
