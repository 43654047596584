import { Component } from "react";
import { connect } from "react-redux";
import { func, string } from "prop-types";
import ImagePreview from "Components/modules/common/ImagePreview";
import PageContent from "Components/PageContent";
import { retry } from "logic/actions/faceCapture";
import { nextStep, go } from "logic/actions/navigation";
import * as S from "./PageFaceCaptureConfirm.styles";
import DaonButton from "../../../common/DaonButton";
import DaonErrorBox from "Components/common/DaonErrorBox";
import {
  logEvent,
  step_completed,
  face_capture_retry,
} from "logic/eventLogger";
import { withTranslation } from "react-i18next";
import { getTimePassed } from "logic/stepTimers";
import { isMobile } from "../../../../logic/deviceType";

export class PageFaceCaptureConfirm extends Component {
  static propTypes = {
    retry: func,
    go: func,
    base64: string,
  };

  constructor(props) {
    super(props);
    this.state = {
      showEnlargedImage: false,
      retry: true,
    };
    this.getBack.bind(this);
    this.submit.bind(this);
  }

  getBack = () => {
    this.props.retry();
    logEvent(face_capture_retry, { retryCount: this.props.retries + 1 });
  };

  componentDidMount() {
    const wrapperDiv = document.getElementById("face_wrapper");
    this.setState({
      width: wrapperDiv.offsetWidth,
      height: wrapperDiv.offsetHeight,
    });
    document.title = `${this.props.t("PageFaceCapture.title")} | Onboarding`;
    const paraElement = document.getElementById("page-notification");
    if (paraElement) {
      paraElement.focus();
    }
  }

  submit = () => {
    const stepName = "face_capture";
    const timePassed = getTimePassed(stepName);
    logEvent(step_completed, {
      stepNameCompleted: stepName,
      timePassed,
      totalRetryCount:
        this.props.retries +
          this.props.retryCount +
          this.props.idRndRetryCount || 0,
    });
    this.props.FaceCaptureInstance.destroy();
    this.props.nextStep();
  };

  toggleImage = (event) => {
    this.setState((state) => {
      return { showEnlargedImage: !state.showEnlargedImage };
    });
    if (event) {
      event.stopPropagation();
    }
  };

  showZoomedImage = () => {
    this.state.zoomDocument
      ? this.setState({ showEnlargedImage: false })
      : this.setState({ showEnlargedImage: true, buttonsDisabled: true });
  };
  render() {
    const isActive = this.state.pending && !this.state.error;
    const text = this.props.isRejected
      ? this.props.t("PageFaceCaptureConfirm.retake_selfie")
      : this.props.t("PageFaceCaptureConfirm.happy_with_image");
    return (
      <PageContent
        isDarkMode={true}
        toggleLoading={isActive}
        backButtonClick={this.getBack}
        title={this.props.t("PageFaceCapture.title_confirm")}
      >
        <S.WrapperDiv
          id="face_wrapper"
          isNative={this.props.captureMethod === "NATIVE"}
        >
          <S.Para id="page-notification" tabIndex="-1">{this.props.t("PageFaceCaptureConfirm.pageNotification")}</S.Para>
          <S.Title>{this.props.t("PageFaceCaptureConfirm.title")}</S.Title>
          <S.TextParagraph>{text}</S.TextParagraph>

          <ImagePreview
            showZoomedImage={this.showZoomedImage}
            flipX={true}
            previewImage={this.props.base64}
            className="face-preview-wrapper"
            alt={this.props.t("PageFaceCaptureConfirm.image_alt")}
          />
        </S.WrapperDiv>
        {this.state.error && (
          <S.ErrorWrap>
            <DaonErrorBox
              message={this.state.error}
              title={this.props.t("PageFaceCaptureConfirm.error_type")}
            />
          </S.ErrorWrap>
        )}
        {this.props.isRejected ? (
          <div className="message-box-error">
            {this.props.message}
            <DaonButton
              disabled={this.state.pending}
              onClick={this.getBack}
              outline
              id="retake_photo"
            >
              {this.props.t("PageFaceCaptureConfirm.retake_photo")}
            </DaonButton>
          </div>
        ) : (
          <S.ButtonsWrapper>
            <DaonButton
              style={
                isMobile(navigator.userAgent)
                  ? { margin: "0 0 1rem 0" }
                  : { margin: "1rem" }
              }
              disabled={this.state.pending}
              onClick={this.getBack}
              outline
              id="retake_photo"
            >
              {this.props.t("PageFaceCaptureConfirm.retake_photo")}
            </DaonButton>

            <DaonButton
              disabled={this.state.pending || this.state.error}
              onClick={this.submit}
              style={
                isMobile(navigator.userAgent)
                  ? { margin: "0 0 1rem 0" }
                  : { margin: "1rem" }
              }
              id="submit_photo"
            >
              {this.props.t("Common.photo_ok_face")}
            </DaonButton>
          </S.ButtonsWrapper>
        )}
      </PageContent>
    );
  }
}

const componentWithTranslation = withTranslation()(PageFaceCaptureConfirm);
export default connect(
  (state) => {
    const isFacePlusEnabled =
      state.configuration.extraConfig?.steps?.face?.options?.isFacePlusEnabled;
    const { retryCount, idRndRetryCount } = state.livenessTest;
    return {
      ...state.faceCapture,
      retryCount,
      idRndRetryCount,
      isFacePlusEnabled,
    };
  },
  { retry, nextStep, go }
)(componentWithTranslation);
