import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import { version } from "Components/Debug/Version";
import { getTenantNameFromHost } from "logic/tenant";

import { subscribeLoggingFunction } from "../eventLogger";

export function setup(config) {
  if (config.browserLogs) {
    datadogLogs.init({ ...config.browserLogs, version });

    subscribeLoggingFunction((logText, params) => {
      datadogLogs.logger.info(logText, {
        name: params ? params.name || params.value || "" : "",
        env: config.browserLogs.env,
      });
    });
  }

  if (config.browserRum) {
    datadogRum.init({ ...config.browserRum, premiumSampleRate: 0, version, });
    datadogRum.addRumGlobalContext("tenant", getTenantNameFromHost());

    // datadogRum.startSessionReplayRecording();

    subscribeLoggingFunction((logText, params) => {
      datadogRum.addAction(logText, params);
    });
  }
}

export function setUser(id, idCheck) {
  datadogRum.setUser({ id, idCheck });
}
