import styled from "styled-components";

export const LinkText = styled.a`
  color: ${(props) => props.theme.mainColor};
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  text-decoration-line: underline;
  margin: 16px;
  margin-left: ${(props) => (props.upper ? "4px" : "0px")};
`;

export const MobileLinkText = styled(LinkText)`
  overflow-wrap: break-word;
  hyphens: auto;
  display: block;
  margin: 0;
  width: 85%;
  font-size: 14px;
  margin-left: ${(props) => (props.upper ? "4px" : "0px")};
`;

export const MobileButton = styled.button`
  display:flex;
  align-items: center;
  text-align: left;
  background: ${(props) => props.theme.panelBackground};
  border-radius: 7px;
  padding: 15px;
  text-transform: capitalize;
  cursor: pointer;
  margin: 0 4px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  position: relative;
  z-index: 8;
  min-height: 78vh;

  @media only screen and (min-width: ${(props) =>
      props.theme.phoneBreakpoint + "px"}) {
    justify-content: center;
  }
`;

export const CardListItem = styled.div`
  display: flex;
  @media only screen and (max-width: ${(props) =>
          props.theme.phoneBreakpoint + "px"}) {
    flex-direction: column;
  }
  gap: 10px;
  margin-bottom: 1rem;
`;

export const CheckWrapper = styled.label`
  display: flex;
`;

export const BoxDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const ErrorWrap = styled.div`
  @media only screen and (max-width: ${(props) =>
      props.theme.phoneBreakpoint + "px"}) {
    margin-bottom: 9rem;
  }
`;
export const WarningWrap = styled.div`
  @media only screen and (max-width: ${(props) =>
      props.theme.phoneBreakpoint + "px"}) {
  }
`;

export const ModalFooter = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px; /* Set the fixed height of the footer here */
  line-height: 60px; /* Vertically center the text there */
  background-color: #f5f5f5;
`;
export const TermPolicyButton = styled.button`
  color: ${(props) => props.theme.mainColor};
  cursor: pointer;
  background-color: white;
`;
