import styled from "styled-components";

export const WrapperDiv = styled.div`
  position: relative;
  max-width: 90vw;
  #wrapperDiv {
    ${(props) => {
      return props.isNative ? "transform: scaleX(-1)" : "";
    }}
  }

  & .loaded-image-container {
    pointer-events: none;
    height: 85%;
    padding-top: 7.5%;
    padding-bottom: 7.5%;
  }

  & .rotate-mobile-native {
    transform: rotate(90deg);
  }
`;

export const Title = styled.h2`
  color: ${(props) => props.theme.previewPageText};
  font-size: 1.5em;
  font-weight: 600;
  margin: 2em 0;
  text-align: center;

  @media (max-width: ${(props) => props.theme.phoneBreakpoint + "px"}) {
    text-align: start;
    margin: 1em 0;
  }
`;
export const TextParagraph = styled.p`
  text-align: center;
  color: ${(props) => props.theme.previewPageText};
  @media (max-width: ${(props) => props.theme.phoneBreakpoint + "px"}) {
    text-align: start;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: ${(props) => props.theme.phoneBreakpoint + "px"}) {
    flex-direction: column;
    margin-bottom: 1.25rem;
  }
`;

export const ErrorWrap = styled.div`
  margin: 0 auto;
  width: 500px;
  @media (max-width: ${(props) =>
      props.theme.phoneBreakpoint + "px"}) and (orientation: portrait) {
    width: 100%;
    max-width: 100%;
  }
`;

export const EnlargedImage = styled.div`
  display: flex;
  position: fixed;
  height: 100%;
  width: 100%;
  background: red;
  z-index: 1000;
  top: 0;
  left: 0;
`;
export const Para = styled.p`
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  `;
